<template>
  <div class="container container-cozy">
    <div class="row">
      <div class="col-12 col-lg-12">
        <header class="mb-5 text-center">
          <h2>Sua cotação nas principais seguradoras do mercado</h2>
          <p class="mb-0">
            Aguarde enquanto buscamos pelo melhor preço,<br />ou siga o
            preenchimento para ser notificado se alguma cotação chegar
          </p>
          <b-spinner class="mt-3" v-if="loading"></b-spinner>
        </header>
      </div>
    </div>

    <div v-if="alertMessage" class="col-12">
      <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>

    <section id="section-resumo-cotacao">
      <div class="wrapper-cotacoes-seguradoras">
        <div
          :class="getInsurerRowClass(insurer) + ' card-seguradora'"
          v-for="(insurer, index) in insurersOrdered"
          :key="insurer.identifier"
        >
          <input
            type="radio"
            name="seguradora"
            v-model="insurerSelected"
            :value="insurer.identifier"
            :id="'radio-card-' + index"
            class="d-none-billet"
          />
          <label class="card-seguradora-body" :for="'radio-card-' + index">
            <div class="cell">
              <img v-if="insurer.icon" :src="insurer.icon" class="logo-seguradora" />
            </div>

            <div v-if="insurer.loading" class="m-auto">
              <b-spinner v-if="insurer.loading"></b-spinner>
            </div>

            <template v-else>
              <div
                class="cell flex-grow-1 justify"
                v-if="insurer.error && !insurer.loading"
              >
              {{ insurer.error }}
              </div>
              <div
                class="taxa mb-2 mb-lg-0 cell"
                v-if="!insurer.error & !insurer.loading"
              >
                <span class="label">Taxa</span>
                <strong>{{
                  insurer.tax ? formatPercentage(insurer.tax) : ""
                }}</strong>
              </div>
              <div
                class="limite mb-2 mb-lg-0 cell"
                v-if="!insurer.error & !insurer.loading"
              >
                <span class="label">Limite</span>
                {{
                  insurer.limit != 0
                    ? formatCurrency(insurer.limit)
                    : "Sob consulta"
                }}
              </div>
              <div
                class="preco mb-2 mb-lg-0 cell"
                v-if="!insurer.error & !insurer.loading"
              >
                <span class="label">Prêmio</span>
                <strong>{{
                  insurer.price ? formatCurrency(insurer.price) : ""
                }}</strong>
              </div>
              <div
                class="wrapper-botao mt-3 mt-lg-0 cell"
                v-if="!insurer.error & !insurer.loading"
              >
                <div class="botao text-uppercase font-12">
                  <i class="far fa-circle mr-1 unchecked"></i
                  ><i class="fa fa-check-circle checked mr-1"></i> Selecionar
                </div>
              </div>
            </template>
          </label>
        </div>
      </div>

      <div v-if="showCheckboxAdvance" class="mb-4">
        <b-form-checkbox v-model="skipInsurerSelection" name="checkbox-1">
          Avançar sem escolher uma seguradora.
        </b-form-checkbox>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12 mt-5">
          <a
            @click="back()"
            class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-2"
            ><i class="fa fa-angle-left mr-2"></i
            ><span class="d-none-billet d-md-inline-block"> Voltar</span></a
          >
          <button
            :disabled="checkDisableAdvance"
            @click="save()"
            class="btn btn-primary btn-lg text-uppercase px-4"
            data-toggle="modal"
            data-target="#modal-cnpj"
          >
            Gerar Minuta <i class="fa fa-angle-right ml-2"></i>
          </button>
          <span
            v-b-tooltip.hover
            :title="tooltipTitle"
            class="ml-2"
            v-if="checkDisableAdvance"
          >
            <i class="fas fa-exclamation-circle text-warning"></i>
          </span>
          <div
            v-if="isLoading"
            class="overlay d-flex align-items-center justify-content-center"
          >
            <div>
              <div class="d-flex justify-content-center mb-2">
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <div class="d-flex align-items-center">
                <strong>Estamos gerando sua minuta, por favor aguarde.</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import numericFormatterMixin from "@/mixins/numericFormatter";
import uiHelperMixin from "@/mixins/uiHelper";

export default {
  name: "QuotationSummary",
  mixins: [numericFormatterMixin, uiHelperMixin],
  props: {
    quotationId: {
      type: String,
    },
  },
  data: () => ({
    insurerSelected: null,
    loading: false,
    skipInsurerSelection: false,
    isLoading: false,
    insurers: [],
    dataForm: {
      status: 1,
    },
  }),
  created() {
    this.getSupportedInsurers();
  },
  computed: {
    infoTitle() {
      return this.showCheckboxAdvance
        ? "Aguarde enquanto buscamos pelo melhor preço,<br />caso nenhuma seguradora retorne, siga o preenchimento<br /> para oferecermos outras opções para você."
        : "Para prosseguir, por favor escolha uma seguradora.";
    },
    tooltipTitle() {
      return this.showCheckboxAdvance
        ? "Necessário escolher uma seguradora ou confirmar o prosseguimento sem a escolha de uma."
        : "Para prosseguir, por favor escolha uma seguradora.";
    },
    checkDisableAdvance() {
      return !this.insurerSelected && !this.skipInsurerSelection;
    },

    insurersOrdered() {
      return this.insurers.slice().sort(function (a, b) {
        if (a.error && b.error) {
          if (a.error > b.error) {
            return -1;
          }
          if (a.error < b.error) {
            return 1;
          }
          return 0;
        }

        if (!a.error && b.error) {
          return -1;
        }

        if (a.error && !b.error) {
          return 1;
        }

        if (!a.loading && b.loading) {
          return -1;
        }

        if (a.loading && !b.loading) {
          return 1;
        }

        return 0;
      });
    },
    showCheckboxAdvance() {
      return (
        this.insurersOrdered.findIndex((val) => !val.error && !val.loading) ==
        -1
      );
    },
  },
  methods: {
    getSupportedInsurers() {
      this.loading = true;
      quotationService
        .getInsurers(this.quotationId)
        .then((response) => {
          this.insurers = response.data.map((insurer) => ({
            ...insurer,
            loading: !insurer.block,
            error: insurer.block ? "Não foi possível obter a cotação" : null,
          }));
          this.requestInsurersQuotations();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    requestInsurersQuotations() {
      this.insurers
      .filter((insurer) => !insurer.block)
      .forEach((insurer) => {
        this.getInsurerQuotation(insurer.identifier, insurer.url);
      });
    },
    getInsurerQuotation(insurerIdentifier, insurerQuotationUrl) {
      const insurerIndex = this.insurers.findIndex(
        (insurer) => insurer.identifier == insurerIdentifier
      );
      quotationService
        .getInsurerQuotation(insurerQuotationUrl)
        .then((response) => {
          if (
            this.insurers[insurerIndex].with_labor_covarage &&
            !this.insurers[insurerIndex].has_labor_covarage
          ) {
            this.$set(this.insurers, insurerIndex, {
              ...this.insurers[insurerIndex],
              error: "Não possui cobertura trabalhista",
            });
            return;
          }
          this.$set(this.insurers, insurerIndex, {
            ...this.insurers[insurerIndex],
            ...response.data,
          });
        })
        .catch((error) => {
          this.$set(this.insurers, insurerIndex, {
            ...this.insurers[insurerIndex],
            error: error.response && error.response.status == 404 ?
              "Integração não disponível" :
              "Não foi possível obter a cotação",
          });
        })
        .finally(() => {
          this.$set(this.insurers, insurerIndex, {
            ...this.insurers[insurerIndex],
            loading: false,
          });
        });
    },
    getInsurerRowClass(insurer) {
      return !insurer.loading && !insurer.error && ((insurer.with_labor_covarage && insurer.has_labor_covarage) || !insurer.with_labor_covarage) ? "disponivel" :  "indisponivel";
    },
    back() {
      this.$router.push({
        name: "quotation-details",
        params: { quotationId: this.quotationId },
      });
    },
    save() {
      if (!this.insurerSelected) {
        this.nextStep();
        return;
      }

      quotationService
        .updateGuarantee(this.quotationId, {
          insurer_selected: this.insurerSelected,
        })
        .then(() => {
          this.isLoading = true;
          quotationService
            .conclusionQuotation(this.quotationId)
            .then((response) => {
              if (response.status != 200) {
                this.showError(response.data.message);
                return;
              }
              this.nextStep();
            })
            .catch(() => {
              this.showError("Cotação não foi aprovada por esta seguradora.");
              this.scroll();
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          this.showError(error.response.data.error);
        });
    },
    nextStep() {
      this.$router.push({
        name: "final-feedback-quotation",
        params: { quotationId: this.quotationId },
      });
    },
  },
};
</script>

<style>

</style>
